/* src/styles.css */

/* Tailwind's base styles */
@tailwind base;

/* Tailwind's component styles */
@tailwind components;

/* Tailwind's utilities */
@tailwind utilities;


body {
	background-color: #FAFAFA;
	color: rgb(75, 86, 117);
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	font-weight: 400;
	font-family: Inter,Helvetica,system-ui,sans-serif;
}

:focus-visible {
  outline: none;
}

.modal-overlay:duration-500 {
  transition-duration: 500ms;
}

.modal-overlay-open\:opacity-100 {
  opacity: 1;
}
.modal-overlay-open\:mt-7 {
  margin-top: 1.75rem;
}

#sidebar {
	background-color: #FFFFFF;
}

#main {
}

.wrapper {
	overflow: hidden;
}

.content-container {
	flex-grow: 1;
	width: 100%;
	padding-inline-start: 1rem;
	padding-inline-end: 1rem;
}

.content-container table tr {
	height: 49px;
}

.sidebar-wrapper {
}

.sidebar-menu .sidebar-header {
}

.sidebar-menu .sidebar-main {
}

li.sidebar-item {
	font-weight: 500;
}

li.sidebar-item .sidebar-icon {
  width: 22px;
  height: 22px;
}

.sidebar-menu .sidebar-footer {
}

.sidebar-footer .user-details {
}

.sidebar-footer .sidebar-icon {
	width: 38px;
  height: 38px;
}

@media (min-width: 1280px) {
  .content-container {
    margin-inline-start: auto;
    margin-inline-end: auto;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    max-width: 1280px;
  }
}

#table-search {
  height: 34px;
}

/* WebKit Browsers (Chrome, Safari) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Plastischer Effekt */
  cursor: pointer;
  border: none;
}

/* Firefox */
input[type="range"]::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Plastischer Effekt */
  cursor: pointer;
  border: none;
}

/* Edge and IE */
input[type="range"]::-ms-thumb {
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Plastischer Effekt */
  cursor: pointer;
  border: none;
}